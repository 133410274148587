<template>
  <div class="service_all_wrap">
    <div class="service_wrap">
      <!-- :style="`background:url('${bgUrl}')`" -->
      <!-- <h2>交易数据</h2> -->
      <!-- <h4 style="color: #545454">
        文安县城发集团竭诚为您提供最优质的服务与满意问答
      </h4> -->
      <div class="service_info_wrap">
        <div
          class="service_info_item"
          v-for="(item, index) in infoData"
          :key="item.label"
        > 
		<img v-if="item.label == '入驻数量'"
		  src="@/static/common/rj.png"  style="margin-bottom: 10px;"
		/>
		<img v-if="item.label == '工程项目'"
		  src="@/static/common/gc.png"  style="margin-bottom: 10px;"
		/>
		<img v-if="item.label == '货物项目'"
		  src="@/static/common/cg.png" style="margin-bottom: 10px;"
		/>
		<img v-if="item.label == '服务项目'"
		  src="@/static/common/dk.png"  style="margin-bottom: 10px;"
		/>
		<img v-if="item.label == '累计项目金额'"
		  src="@/static/common/lj.png"  style="margin-bottom: 10px;"
		/>
          <div class="service_item_num" v-if="index != 4" style="margin-bottom: 10px;">
            {{
              20 <= item.num
                ? item.num < 100
                  ? parseInt(item.num / 10) * 10 + "+"
                  : parseInt(item.num / 100) * 100 + "+"
                : item.num
            }}
          </div>

          <div class="service_item_num" v-if="index == 4">
            {{
              item.num >= 10000
                ? parseInt(item.num / 10000) + "+"
                : 100 <= item.num && item.num < 10000
                ? parseInt(item.num / 100) * 100 + "+"
                : item.num
            }}
          </div>

          <div class="service_item_label">
            {{ item.label
            }}{{ index == 4 && item.num >= 10000 ? "（万元）" : "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
import imgData from "@/data";
export default {
  setup() {
    // const { Statistics } = storeToRefs(mainStore());
    const store = storeToRefs(mainStore());
    const infoData = computed(() => {
      return store.Statistics;
    });
    // const infoData = [
    //   {
    //     label: "入驻数量",
    //     num: 15,
    //   },
    //   {
    //     label: "成交数量",
    //     num: 0,
    //   },
    //   {
    //     label: "工程项目",
    //     num: 10,
    //   },
    //   {
    //     label: "采购项目",
    //     num: 21
    //   },
    //   {
    //     label: "累计成交金额",
    //     num: 11111,
    //   },
    // ];
    const bgUrl = imgData.serviceBg;
    const data = reactive({
      infoData,
      bgUrl,
    });
    return {
      ...data,
    };
  },
};
</script>

<style scoped>
@import url("./Service.css");
</style>

