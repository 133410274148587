<template>
	<BasePage :bannerUrl="indexBannerImg" subTilte="招标有限公司欢迎您" content="规范服务 / 阳光清廉 / 示范引领 / 降本增效">
		<!-- <DlogAd /> -->
		<div class="Sidebar">
		    <div class="box1">
		      <div class="picture5" id="div1" @click="goToRegister()">
		        <a :href="registerAddress">
		        <img src="@/static/common/zhuce.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
		        <p style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">用户注册</p>
		        </a>
		      </div>
		    </div>
		    <!-- <div class="box2" @click="goToLogin()">
		      <div class="picture6" id="div5">
		        <a :href="address">
		        <img src="@/static/common/denglu.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
		        <p  style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">系统登录</p>
		        </a>
		      </div>
		    </div> -->
		    <div class="box3" style="display: flex;justify-content: flex-end;margin-top: 9px;">
		      <div class="title1" id="div3">
		        <div class="title1-box">
		          <p style="margin:0; margin-left:10px; font-size:18px; margin-bottom:5px;">联系电话：</p>
		          <p style="margin:0; margin-left:10px; font-size:18px;">0316-5286812</p>
		        </div>
		      </div>
		       <div class="picture7" id="div4" @mousemove="pop">
		          <img src="@/static/common/dianhua.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
		        <p style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">联系电话</p>
		       </div>
		    </div>
			
			<div class="box4" style="display: flex;justify-content: flex-end;margin-top: 9px;">
			  <div class="title2" id="div7">
			    <div class="title2-box">
			      <img src="@/static/common/lfwa.png" />
			    </div>
			  </div>
			   <div class="picture8" id="div8" @mousemove="pop1">
			      <img src="@/static/common/vx.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
			    <p style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">在线客服</p>
			   </div>
			</div>
		  </div>
		<NewsCenter style="padding-top: 80px" />
		<!-- <Purchasing /> -->
		<div style="background:#f3f3f3;">
			<BaseModular subTitle="招标信息" />
			<LiAction />
			<SearchFormType />
			<newBiddingTable style="margin-bottom: 30px;"/>
		</div>
		
		<!-- <Engineering style="margin-bottom: 30px;" /> -->
		<!-- <AdBanner /> -->
		<div style="width: 100%;height: 125px;position: relative;margin-bottom: 70px;">
			<img src="@/static/common/gg.png" style="margin-right: 7px;position: absolute;width:100%;" />
			<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);text-align: center;">
				<p style="font-size: 24px;color: #fff;margin-bottom: 16px;">一站式沟通服务 全流程线上采购</p>
				<div class="immediately" @click="goToRegister()" style="">立即注册</div>
			</div>
		</div>
		<Service style="margin-bottom: 120px;" />
		<!-- <AdBanner2 /> -->
		<!-- <HelpCenter ref="HelpCenter" /> -->
		<div class="help" style="background: #F3F3F3;width: 100%;">
			<div style="width:1300px;display: flex;
		justify-content: space-around;margin: 0 auto;">
				<div class="box">
					<div class="bj"
						style="width: 100%;height: 140px;display: flex;justify-content: center;align-items: center;">
						<div>
							<p style="font-size: 26px;text-align: center;margin-bottom: 8px;color: #fff;">使用指南</p>
							<div style="width: 200px;
					height: 2px;background: rgba(255, 255, 255, 1);"></div>
							<p style="font-size: 16px;text-align: center;margin-top: 8px;color: #fff;cursor: pointer;" @click="toNewsPage">查看更多>>></p>
						</div>
					</div>
					<div style="padding-left: 30px;
    padding-right: 30px;padding-top: 30px;padding-bottom: 16px;"  >
						<div class="alignment" style="margin-bottom: 10px;cursor: pointer;" v-for="item in useData" :key="item"  @click="setHtml(item)">
							<p class="omit" style="font-size: 14px;">{{ item.title }}</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);display: inline-block;">{{ time(item.createTime) }}</p>
						</div>
						<!-- <div style="margin-bottom: 10px;">
							<p style="font-size: 14px;">关于客服值班时间调整通知</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);">2023-01-01</p>
						</div>
						<div style="margin-bottom: 10px;">
							<p style="font-size: 14px;">关于客服值班时间调整通知</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);">2023-01-01</p>
						</div>
						<div style="margin-bottom: 10px;">
							<p style="font-size: 14px;">关于客服值班时间调整通知</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);">2023-01-01</p>
						</div>
						<div style="margin-bottom: 10px;">
							<p style="font-size: 14px;">关于客服值班时间调整通知</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);">2023-01-01</p>
						</div> -->
					</div>
				</div>
				<div class="box">
					<div class="bj1"
						style="width: 100%;height: 140px;display: flex;justify-content: center;align-items: center;">
						<div>
							<p style="font-size: 26px;text-align: center;margin-bottom: 8px;color: #fff;" >帮助中心</p>
							<div style="width: 200px;
							height: 2px;background: rgba(255, 255, 255, 1);"></div>
							<p style="font-size: 16px;text-align: center;margin-top: 8px;color: #fff;cursor: pointer;" @click="toNewsPage1" >查看更多>>></p>
						</div>
					</div>
					<div style="padding-left: 30px;
			padding-right: 30px;padding-top: 30px;padding-bottom: 16px;">
						<div class="alignment" style="margin-bottom: 28px;cursor: pointer;" v-for="item in helpData" :key="item"  @click="setHtml(item)">
							<p :title="item.title" class="omit" style="font-size: 14px;">{{ item.title }}</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);display: inline-block;">{{ time(item.createTime) }}</p>
						</div>
						<!-- <div style="margin-bottom: 28px;">
							<p style="font-size: 14px;">如何知道自己单位是否已在平台注册？</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);"></p>
						</div>
						<div style="margin-bottom: 28px;">
							<p style="font-size: 14px;">注册审核需要多长时间，如何知道审核结果？</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);"></p>
						</div>
						<div style="margin-bottom: 28px;">
							<p style="font-size: 14px;">CA登录提示:已注册尚未绑定CA,请绑定后再CA登录?</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);"></p>
						</div>
						<div style="margin-bottom: 28px;">
							<p style="font-size: 14px;">CA登录提示：数据提交中？</p>
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);"></p>
						</div> -->
					</div>
				</div>
				<div class="box">
					<div class="bj2"
						style="width: 100%;height: 140px;display: flex;justify-content: center;align-items: center;">
						<div>
							<p style="font-size: 26px;text-align: center;margin-bottom: 8px;color: #fff;">下载中心</p>
							<div style="width: 200px;
							height: 2px;background: rgba(255, 255, 255, 1);"></div>
							<p style="font-size: 16px;text-align: center;margin-top: 8px;color: #fff;cursor: pointer;" @click="toNewsPage2">查看更多>>></p>
						</div>
					</div>
					<div style="padding-left: 30px;
			padding-right: 30px;padding-top: 30px;padding-bottom: 16px;">
						<div class="alignment" style="margin-bottom: 28px;cursor: pointer;" v-for="item in toolData" :key="item"  @click="setHtml(item)">
							<div style="display: flex;
    align-items: center;cursor: pointer;">
								<img style="margin-right: 10px;width: 16px;" src="@/static/common/xz.png"  />
								<p style="font-size: 14px;">{{ item.title }}</p>
							</div>
							
							<p style="text-align: end;font-size: 12px;color: rgba(128, 128, 128, 1);display: inline-block;">{{ time(item.createTime) }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="foot">
			<div class="footBox">
				<p style="font-size: 24px;color: rgba(255, 255, 255, 1);margin-bottom: 30px;">扫码关注</p>
				<p style="font-size:16px ;color: rgba(255, 255, 255, 1);margin-bottom: 10px;">请扫码关注公众号，了解更多资讯</p>
				<img src="@/static/common/lfwa.png" />
			</div>
			<div class="footBox">
				<p style="font-size: 24px;color: rgba(255, 255, 255, 1);margin-bottom: 32px;">联系我们</p>
				<div>
					<p class="foot-right">地址：河北省文安县经济开发区月星生活广场写字楼15层</p>
					<p class="foot-right">咨询热线：0316-5286812</p>
					<p class="foot-right">邮箱：wenancfjt@163.com</p>

				</div>
			</div>
			<img src="@/static/common/foot1.png" style="position: absolute;bottom: 0;width: 100%;height: 235px;" />
		</div>
		<div>
			<p style="margin-top:10px;margin-bottom:10px;text-align:center;font-size:13px;color:#606266"><a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2023034521号</a></p>
		</div>
	</BasePage>
</template>


<script>
	import BasePage from "@/components/BasePage/BasePage";
	import imgData from "@/data.js";
	import Service from "./components/Service/Service";
	import Purchasing from "./components/Purchasing/Purchasing";
	import AdBanner from "./components/AdBanner/AdBanner";
	import newBiddingTable from "../Bidding/components/newBiddingTable/newBiddingTable";
	import SearchFormType from "../Bidding/components/SearchFormType/SearchFormType";
	import LiAction from "../Bidding/components/LiAction/LiAction";
	import BaseModular from "@/components/BaseModular/BaseModular.vue";
	import Engineering from "./components/Engineering/Engineering";
	import AdBanner2 from "./components/AdBanner2/AdBanner";
	import HelpCenter from "./components/HelpCenter/HelpCenter";
	import DlogAd from "./components/DlogAd/DlogAd";
	import NewsCenter from "./components/NewsCenter/NewsCenter";
	import {stateStore } from "@/store";
	import { useRouter } from "vue-router";
	import {
		reactive,
		computed
	} from "vue";
	import {
		mainStore
	} from "@/store/index";
	import {
		storeToRefs
	} from "pinia";

	export default {
		components: {
			BasePage,
			Service,
			Purchasing,
			Engineering,
			HelpCenter,
			AdBanner,
			DlogAd,
			AdBanner2,
			NewsCenter,
			newBiddingTable,
			SearchFormType,
			BaseModular,
			LiAction
		},
		setup() {
			const storeOfmain = mainStore();
			const router = useRouter();
			const store = mainStore();
			const AdData = computed(() => {
				return storeToRefs(store).AdData;
			});
			const useData = computed(() => storeToRefs(store).useAllData);
			const useId = useData
			const helpData = computed(() => storeToRefs(store).helpAllData);
			console.log('cccc',helpData)
			const toolData = computed(() => storeToRefs(store).toolAllData);
			const setHtml = (item) => {
			  stateStore().setactiveHtml(
			    item.content,
			    item.title,
			    item.createTime,
			    item
			  );
			  window.open(
			    router.resolve({
			      path: "/InfoView",
			      query: { id: item.id, type: item.title },
			    }).href,
			    "_blank"
			  );
			};
			const toNewsPage = () => {
			  storeOfmain.setData("news_ActiveType", "use");
			  router.push({
				path: "/ListOfNews",
			  });
			};
			const toNewsPage1 = () => {
			  storeOfmain.setData("news_ActiveType", "help");
			  router.push({
				path: "/ListOfNews",
			  });
			};
			const toNewsPage2 = () => {
			  storeOfmain.setData("news_ActiveType", "tool");
			  router.push({
				path: "/ListOfNews",
			  });
			};
			const getUrl = (name) => {
			  return window.location.origin + "/" + name;
			};
			const data = reactive({
				AdData,
				useData,
				helpData,
				toolData
			});

			return {
				...imgData,
				...data,
				getUrl,
				setHtml,
				toNewsPage,
				toNewsPage1,
				toNewsPage2
			};
		},
		methods: {
			time(value){
		  console.log('4444',typeof(value))
		      var timeArr = value.replace(" ", ":").replace(/\:/g, "-").split("-");
		      var nian = timeArr[0];
		      var yue = timeArr[1];
		      var ri = timeArr[2];
			  var str = nian + '-' + yue + '-' + ri;
			  return str
			},
			goToRegister() {
				window.open(process.env.VUE_APP_SEVERCE_REGISTER, "_blank");
				// location.href = process.env.VUE_APP_SEVERCE_REGISTER;
			},
			toHelpCenter() {
				this.$refs.HelpCenter.handleToHelpCenter();
			},
			pop(){
			    var oDiv = document.getElementById("div4");
			    var oD = document.getElementById("div3");
			    oDiv.onmouseover = function () {
			    oD.style.display = "block"
			    };
			    oDiv.onmouseout = function () {
			    oD.style.display = "none"
			    };
			},
			pop1(){
			    var oDiv = document.getElementById("div8");
			    var oD = document.getElementById("div7");
			    oDiv.onmouseover = function () {
			    oD.style.display = "block"
			    };
			    oDiv.onmouseout = function () {
			    oD.style.display = "none"
			    };
			},
		},
	};
</script>

<style scoped>
	a {
		text-decoration: none;
		color: #606266;
	}
	a:hover {
        color: #000;
    }
	.help {
		/* width: 1300px; */
		/* height: 382px; */
		margin: 0 auto;
		/* border: 1px solid; */

		padding-bottom: 50px;
		padding-top: 50px;

	}

	.box {
		width: 413px;
		/* height: 382px; */
		/* border: 1px solid; */
		border-radius: 20px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	}

	.foot {
		/* width: 100%; */
		height: 320px;
		background: url(../../static/common/foot.png);
		border: 1px solid;
		display: flex;
		position: relative;
	}

	.bj {
		background-image: url(../../static/common/syzn.png);
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.bj1 {
		background-image: url(../../static/common/bzzx.png);
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.bj2 {
		background-image: url(../../static/common/xzzx.png);
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.immediately {
		width: 120px;
		height: 40px;
		border-radius: 4px;
		background: linear-gradient(90deg, rgba(112, 23, 217, 1) 0%, rgba(21, 98, 206, 1) 100%);
		border: 1px solid rgba(255, 255, 255, 0.4);
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		cursor: pointer;
	}

	.footBox {
		margin-left: 313px;
		margin-top: 68px;
		width: 301px;
		z-index: 999;
	}

	.foot-right {
		font-size: 15px;
		color: #fff;
		margin-bottom: 10px;
	}
	.omit{
		display: inline-block;
		    width: 180px;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
	}
	.alignment{
		display: flex;
		    justify-content: space-between;
			margin-bottom: 28px!important;
	}
	.Sidebar{position: fixed;top: 40%;right: 101px;z-index:99;}
	.box1{display: flex;margin-bottom: 10px; justify-content: flex-end;}
	.picture5{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.title1{background-color: #fff;width: 180px;height: 84px; display:none;}
	.title1-box{ margin-top: 20px;justify-content: flex-end; }
	.title2{background-color: #fff;width: 90px;height: 84px; display:none;}
	.title2-box{justify-content: flex-end; }
	.box2{display: flex;justify-content: flex-end;}
	.box4{display: flex;justify-content: flex-end;}
	.picture6{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.picture7{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.picture8{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.titlebox{width:62%;height:10px; display:flex;align-items: center;margin:13px auto;}
</style>
